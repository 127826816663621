




























































































































































































import { getElasticSearchClient } from '@/services/elasticSearch'
import DatePicker from '@/components/reports/DatePicker.vue'
import GroupByIntervalSelect from '@/components/reports/GroupByIntervalSelect.vue'
import ManageFilters from '@/components/reports/ManageFilters.vue'
import Metric from '../../components/reports/Metric.vue'
import { CurrencyEnum } from '../../../../shared/types/types'
import { formatPrice, getCountryName } from '../../lib/utils'
import DatePickerMixin from '../../mixins/DatePicker.mixin'
import { AndFilterGroup, Filter, IntervalEnum } from '../../lib/reports/types'
import {
  elasticSearchFilterQuery,
  filterPriceLabel,
  filterStartCase,
  filterValueLabel,
} from '../../lib/reports/functions'

interface Aggr {
  key: string
  doc_count: number
  totalDiscountPrice: { value: number }
  totalPrice: { value: number }
  originalSubTotalPrice: { value: number }
  shippingPrice: { value: number }
  totalRefunds: { value: number }
  tax: { value: number }
  fee: { value: number }
}

export default DatePickerMixin.extend({
  name: 'Discount',
  components: {
    DatePicker,
    GroupByIntervalSelect,
    Metric,
  },
  filters: {
    filterStartCase,
    filterValueLabel,
    filterPriceLabel,
  },
  data() {
    return {
      switch: false,
      timeInterval: IntervalEnum.Day,
      dataFilters: [
        {
          orGroups: [
            { filter: 'ordersPaymentStatus', operator: 'is', values: ['paid'] },
            {
              filter: 'ordersPaymentStatus',
              operator: 'is',
              values: ['partially_refunded'],
            },
            {
              filter: 'ordersPaymentStatus',
              operator: 'is',
              values: ['refunded'],
            },
          ],
        },
      ] as AndFilterGroup[],
      data: null as Record<string, any> | null,
      visibleColumns: [
        'key',
        'count',
        'totalDiscounts',
        'totalRevenue',
        'shipping',
        'originalPrice',
      ] as string[],
      metrics: {
        totalDiscountPrice: '$0',
        orders: 0,
        discounts: 0,
        uniqueCustomers: 0,
        fee: 0,
      } as {
        totalDiscountPrice: string
        orders: number
        discounts: number
        uniqueCustomers: number
        fee: number
      },
    }
  },
  computed: {
    query(): Record<string, any> {
      return {
        aggs: {
          byDiscount: {
            terms: {
              field: 'discount.name',
              order: {
                _count: 'desc',
              },
              size: 100,
            },
            aggs: {
              totalDiscountPrice: {
                sum: {
                  field: 'totalDiscountPrice.amount',
                },
              },
              totalPrice: {
                sum: {
                  field: 'totalPrice.amount',
                },
              },
              fee: {
                sum: {
                  field: 'fee.amount',
                },
              },
              tax: {
                sum: {
                  field: 'fee.amount',
                },
              },
              totalRefunds: {
                sum: {
                  field: 'totalRefund.amount',
                },
              },
              originalSubTotalPrice: {
                sum: {
                  field: 'originalSubTotalPrice.amount',
                },
              },
              shippingPrice: {
                sum: {
                  field: 'shippingPrice.amount',
                },
              },
            },
          },
        },
        size: 0,
        query: elasticSearchFilterQuery(this.dataFilters, this.datePicker),
      }
    },
    rows(): Aggr[] {
      const buckets = this.data?.aggregations.byDiscount.buckets ?? []
      const sum = buckets.reduce(
        (acc: Aggr, b: Record<string, any>) => {
          acc.totalDiscountPrice.value =
            acc.totalDiscountPrice.value + b.totalDiscountPrice.value
          acc.totalPrice.value = acc.totalPrice.value + b.totalPrice.value
          acc.originalSubTotalPrice.value =
            acc.originalSubTotalPrice.value + b.originalSubTotalPrice.value
          acc.shippingPrice.value =
            acc.shippingPrice.value + b.shippingPrice.value
          acc.totalRefunds.value = acc.totalRefunds.value + b.totalRefunds.value
          acc.fee.value = acc.fee.value + b.fee.value
          acc.tax.value = acc.tax.value + b.tax.value
          acc.doc_count = acc.doc_count + b.doc_count
          return acc
        },
        {
          totalDiscountPrice: { value: 0 },
          totalPrice: { value: 0 },
          originalSubTotalPrice: { value: 0 },
          shippingPrice: { value: 0 },
          totalRefunds: { value: 0 },
          fee: { value: 0 },
          tax: { value: 0 },
          key: 'Summary',
          doc_count: 0,
        } as Aggr
      )

      return [sum, ...buckets]
    },
  },
  watch: {
    datePicker: {
      handler(): void {
        this.fetchData()
      },
      deep: true,
    },
    timeInterval(): void {
      this.fetchData()
    },
    dataFilters: {
      handler(): void {
        this.fetchData()
      },
      deep: true,
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    getCountryName(countryCode: string): string {
      return getCountryName(countryCode)
    },
    formatPrice(price: number): string {
      return formatPrice({ amount: price, currency: CurrencyEnum.Usd })
    },
    async fetchData(): Promise<void> {
      const elasticSearch = getElasticSearchClient()
      const { data } = await elasticSearch.post('/orders/_search', this.query)
      this.data = data

      const { data: totalDiscountPrice } = await elasticSearch.post(
        '/orders/_search',
        {
          aggs: {
            totalDiscountPrice: {
              sum: {
                field: 'totalDiscountPrice.amount',
              },
            },
          },
          size: 0,
          query: elasticSearchFilterQuery(this.dataFilters, this.datePicker),
        }
      )
      this.metrics.totalDiscountPrice = formatPrice({
        amount: totalDiscountPrice.aggregations.totalDiscountPrice.value,
        currency: CurrencyEnum.Usd,
      })

      const { data: orders } = await elasticSearch.post('/orders/_search', {
        aggs: {
          discountedOrders: {
            filter: {
              bool: {
                must: [],
                filter: [
                  {
                    bool: {
                      should: [
                        {
                          exists: {
                            field: 'discount.id',
                          },
                        },
                      ],
                      minimum_should_match: 1,
                    },
                  },
                ],
                should: [],
                must_not: [],
              },
            },
          },
        },
        size: 0,
        query: elasticSearchFilterQuery(this.dataFilters, this.datePicker),
      })
      this.metrics.orders = orders.aggregations.discountedOrders.doc_count

      const { data: discounts } = await elasticSearch.post('/orders/_search', {
        aggs: {
          cardinality: {
            cardinality: {
              field: 'discount.id',
            },
          },
        },
        size: 0,
        query: elasticSearchFilterQuery(this.dataFilters, this.datePicker),
      })
      this.metrics.discounts = discounts.aggregations.cardinality.value

      const { data: uniqueCustomers } = await elasticSearch.post(
        '/orders/_search',
        {
          aggs: {
            hasDiscount: {
              filter: {
                bool: {
                  must: [],
                  filter: [
                    {
                      bool: {
                        should: [
                          {
                            exists: {
                              field: 'discount.id',
                            },
                          },
                        ],
                        minimum_should_match: 1,
                      },
                    },
                  ],
                  should: [],
                  must_not: [],
                },
              },
              aggs: {
                cardinality: {
                  cardinality: {
                    field: 'customer.customerId',
                  },
                },
              },
            },
          },
          size: 0,
          query: elasticSearchFilterQuery(this.dataFilters, this.datePicker),
        }
      )
      this.metrics.uniqueCustomers =
        uniqueCustomers.aggregations.hasDiscount.cardinality.value
    },
    onRangeChanged(range: { startDate: Date; endDate: Date }): void {
      this.datePicker.startDate = range.startDate
      this.datePicker.endDate = range.endDate
    },
    openManageFilters(): void {
      this.$buefy.modal.open({
        parent: this,
        customClass: 'manage-filters',
        scroll: 'keep',
        component: ManageFilters,
        hasModalCard: true,
        props: {
          initFilters: this.dataFilters,
        },
        trapFocus: true,
        events: {
          value: (value: AndFilterGroup[]): void => {
            this.dataFilters = JSON.parse(
              JSON.stringify(value)
            ) as AndFilterGroup[]
          },
        },
      })
    },
    removeAndGroup(group: AndFilterGroup) {
      this.dataFilters.splice(this.dataFilters.indexOf(group), 1)
    },
    removeOrGroup(andGroup: AndFilterGroup, orGroup: Filter) {
      if (andGroup.orGroups.length === 1) {
        // If there are more and groups, but there is only one orGroup inside then remove the andGroup
        this.removeAndGroup(andGroup)
      } else {
        andGroup.orGroups.splice(andGroup.orGroups.indexOf(orGroup), 1)
      }
    },
    originalTotal(row: Aggr) {
      return row.totalPrice.value + row.totalDiscountPrice.value
    },
    totalSales(row: Aggr) {
      return (
        row.totalPrice.value -
        row.totalDiscountPrice.value -
        row.fee.value -
        row.totalRefunds.value +
        row.shippingPrice.value +
        row.tax.value
      )
    },
  },
})
